import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { Fragment, useCallback, useState } from "react";
import CustomAccordion from "../../GlobalComponents/CustomAccordion";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { contentSectionHandleAction, showQuestionsAction } from "../../../store/Matters/mattersSlice";
import ReminderModal from "../../GlobalComponents/ReminderModal";
import AnswerContent from "./AnswerContent";
import reminderElements from "./ReminderElements";
import ShowYesNoBtn from "./ShowYesNoBtn";


const MemberQuestions = ({ members, memberQuestions, sectionId, actId }) => {
    const [{ selectMemberId, selectActId }, setSelectId] = useState({ selectMemberId: null, selectActId: null });
    const [selectChildActId, setSelectChildActId] = useState(null);
    const { open: reminderOpened } = useSelector(state => state.matters.reminderOpened);

    const dispatch = useDispatch();
    const {
        memberID: showMemberId = null,
        actID: showActId = null,
        questionID: showQuestionId = null,
        show: showQuestions = false
    } = useSelector(state => state.matters.showQuestions) || {}

    const showSectionsToggle = useCallback(({showBtn, memberID, id}) => {
        if (showBtn) {
            return showMemberId !== memberID || sectionId !== showActId || showQuestionId !== id || !showQuestions
        } else {
            return showMemberId === memberID && sectionId === showActId && showQuestionId === id && showQuestions
        }
    }, [sectionId, showActId, showMemberId, showQuestionId, showQuestions])
    const handleAnswerUpdate = (answer) => {
        dispatch(contentSectionHandleAction(answer[0] ? [{
            ...answer[0],
            content: JSON.parse(answer[0]?.content)
        }] : [{content: {list: [{ note: { id: Math.random(), value: "" }}]}}]))
    }

    return (
        <Fragment>
            {members.map(({name, id: memberID}) => (
                <Stack key={memberID} gap={1}>
                    <Typography mt="20px">Questions About {name}</Typography>
                    {memberQuestions.map((
                        {
                            description: parentDesc,
                            id,
                            questions: memberChildQuestion = [],
                            title,
                            answer,
                            reminders: remindersParent,
                            has_reminders,
                            position: parentPosition,
                            is_user_defined: is_user_defined_parent,
                            has_yes_no
                        }
                    ) => {
                        const [{ id: answerIdParent = null, content: stringifySchemeParent, going_next: goingNextParent = 1, member_id: answerParentMemberId } = {}] = answer || []
                        const answerContentSchemeParent = stringifySchemeParent && memberID === answerParentMemberId
                            ? JSON.parse(stringifySchemeParent).list
                            : [{ note: { id: Math.random(), value: "" } }]

                        const customParentProps = {
                            answerId: answerIdParent,
                            answerList: answerContentSchemeParent,
                            questionId: id,
                            actId: showActId,
                            going_next: goingNextParent,
                            memberId: memberID,
                            sectionId,
                        }
                        const reminderAttributesParent = {
                            dispatch,
                            childId: id,
                            sectionId,
                            has_reminders: has_reminders,
                            going_next: goingNextParent,
                            showActId,
                            answerId: answerIdParent,
                            answerContentScheme: answerContentSchemeParent,
                            memberInfo: {member_id: memberID, answerMemberId: answerParentMemberId},
                            is_user_defined: is_user_defined_parent
                        }
                        const handleAccordionChange = (event, isExpanded) => {
                            if (isExpanded) {
                                setSelectId({ selectMemberId: memberID, selectActId: id })
                            } else {
                                setSelectId({ selectMemberId: null, selectActId: null })
                            }
                        };
                        return <div key={id} style={{ position: "relative" }}>
                            <CustomAccordion
                                title={title}
                                panelId={id}
                                borderSX={{border: "1px solid #E3E6EF"}}
                                onChange={handleAccordionChange}
                                expanded={id !== selectActId || memberID !== selectMemberId ? {expanded: false} : {expanded: true}}
                                toolTipDesc={parentDesc}
                                showQuestionBtn
                                reminders={remindersParent}
                                componentInfo={{
                                    question_id: id,
                                    dynamic_section_id: sectionId,
                                    member_id: showMemberId
                                }}
                                position={`${parentPosition}. `}
                                memberID={memberID}
                                actId={actId}
                            >
                            {selectActId === id && selectMemberId === memberID ? (
                                <Fragment>
                                    {memberChildQuestion.length > 0 ? (
                                        <Fragment>
                                            <ShowYesNoBtn
                                                has_yes_no={has_yes_no}
                                                showSectionsToggle={showSectionsToggle}
                                                childQuestions={memberChildQuestion}
                                                id={id}
                                                sectionId={sectionId}
                                                memberID={memberID}
                                            />
                                            {showSectionsToggle({showBtn: false, memberID, id}) ? (
                                                <Stack>
                                                    {memberChildQuestion.map((
                                                        {
                                                            id: childId,
                                                            description,
                                                            answer,
                                                            title,
                                                            reminders,
                                                            has_reminders,
                                                            position,
                                                            is_user_defined
                                                        }
                                                    ) => {
                                                        const [{
                                                            id: answerId = null,
                                                            content: stringifyScheme,
                                                            going_next = 1,
                                                            member_id: answerMemberId
                                                        } = {}] = answer || []
                                                        const answerContentScheme = stringifyScheme && showMemberId === answerMemberId
                                                            ? JSON.parse(stringifyScheme).list
                                                            : [{note: {id: Math.random(), value: ""}}]
                                                        const handleAccordionChange2 = (event, isExpanded) => {
                                                            if (isExpanded) {
                                                                setSelectChildActId(childId)
                                                                handleAnswerUpdate(answer)
                                                            } else {
                                                                setSelectChildActId(null)
                                                            }
                                                        };
                                                        const customProps = {
                                                            answerId,
                                                            answerList: answerContentScheme,
                                                            questionId: selectChildActId,
                                                            memberId: showMemberId,
                                                            actId: showActId,
                                                            parentQuestionId: showQuestionId,
                                                            going_next: going_next,
                                                            sectionId,
                                                        }
                                                        const reminderAttributes = {
                                                            dispatch,
                                                            childId,
                                                            sectionId,
                                                            showQuestionId,
                                                            has_reminders,
                                                            going_next,
                                                            showActId,
                                                            answerId,
                                                            answerContentScheme,
                                                            memberInfo: {member_id: showMemberId, answerMemberId},
                                                            is_user_defined
                                                        }
                                                        return (
                                                            <div key={childId} style={{position: "relative"}}>
                                                                <CustomAccordion
                                                                    title={title}
                                                                    panelId={childId}
                                                                    borderSX={{
                                                                        border: "1px solid #E3E6EF",
                                                                        backgroundColor: "grey.200"
                                                                    }}
                                                                    detailsSX={{backgroundColor: "common.white"}}
                                                                    onChange={handleAccordionChange2}
                                                                    expanded={selectChildActId !== childId ? {expanded: false} : {expanded: true}}
                                                                    toolTipDesc={description}
                                                                    reminders={reminders}
                                                                    componentInfo={{
                                                                        question_id: selectChildActId,
                                                                        dynamic_section_id: sectionId,
                                                                        parent_question_id: showQuestionId,
                                                                        member_id: showMemberId
                                                                    }}
                                                                    position={`${position}. `}
                                                                    showQuestionBtn
                                                                    memberID={memberID}
                                                                    actId={actId}
                                                                >
                                                                    {selectChildActId === childId && answerContentScheme.map((elem, componentIndex) => (
                                                                        <AnswerContent {...elem} componentIndex={componentIndex}
                                                                                       customProps={customProps}/>
                                                                    ))}
                                                                    {reminderElements(reminderAttributes).button}
                                                                </CustomAccordion>
                                                                {reminderElements(reminderAttributes).accRightPosition}
                                                            </div>
                                                        )
                                                    })}
                                                </Stack>
                                            ) : null}
                                        </Fragment>
                                    ): (
                                        <Fragment>
                                            {answerContentSchemeParent.map((elem, componentIndex) => (
                                                <AnswerContent {...elem} componentIndex={componentIndex} customProps={customParentProps}/>
                                            ))}
                                            {reminderElements(reminderAttributesParent).button}
                                        </Fragment>
                                    )}
                                </Fragment>
                            ) : null}
                            </CustomAccordion>
                            {reminderElements(reminderAttributesParent).accRightPosition}
                        </div>
                    })}
                </Stack>
            ))}
            {reminderOpened && <ReminderModal />}
        </Fragment>
    )
}

export default MemberQuestions;