import { useDispatch } from "react-redux";
import React, {Fragment, useCallback, useEffect} from "react";
import Button from "@mui/material/Button";
import { showQuestionsAction } from "../../../store/Matters/mattersSlice";

const ShowYesNoBtn = (
    {
        showSectionsToggle,
        has_yes_no,
        childQuestions,
        id,
        sectionId,
        memberID = null
    }
) => {
    const dispatch= useDispatch();
    const handleClick = useCallback((showOptions) => {
        dispatch(showQuestionsAction(showOptions))
    }, [dispatch])

    const memberIdExistRecord = memberID ? {memberID} : {}

    useEffect(() => {
        if (!has_yes_no) {
            dispatch(showQuestionsAction({ ...memberIdExistRecord, actID: sectionId, questionID: id, show: true}))
        }
    }, []);

    return has_yes_no && showSectionsToggle({showBtn: true, memberID, id}) && !childQuestions.find(({ answer }) => {
        if (answer.length > 0) {
            handleClick({ ...memberIdExistRecord, actID: sectionId, questionID: id, show: true})
        }
        return answer.length > 0
    }) ? (
        <Fragment>
            <Button
                sx={{backgroundColor: "info.dark", color: "common.white"}}
                onClick={() => handleClick({ ...memberIdExistRecord, actID: sectionId, questionID: id, show: true})}
            >
                Yes
            </Button>
            <Button
                onClick={() => handleClick({ show: false })}
                sx={{backgroundColor: "grey.300", color: "black.200", ml: "10px"}}
            >
                No
            </Button>
        </Fragment>
    ) : null
}

export default ShowYesNoBtn;
