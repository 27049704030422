import CustomTextArea from "../../GlobalComponents/CustomTextArea";
import CustomCommentList from "../../GlobalComponents/CustomCommentList";
import CustomList from "../../GlobalComponents/CustomList";
import CustomPhrase from "../../GlobalComponents/CustomPhrase";
import CustomAnnexure from "../../GlobalComponents/CustomAnnexure";
import CustomDialog from "../../GlobalComponents/CustomDialog";
import React, {Fragment} from "react";

const AnswerContent = (
    {
        note: componentNote = null,
        commentList: componentCommentList = null,
        list: componentList = null,
        phrase: componentPhrase = null,
        annexure: componentAnnexure = null,
        dialog: componentDialog = null,
        componentIndex,
        customProps
    },
) => {
    return (
        <Fragment>
            {componentNote && (
                <CustomTextArea
                    note={componentNote}
                    componentIndex={componentIndex}
                    {...customProps}
                />
            )}
            {componentCommentList?.length > 0 && (
                <CustomCommentList
                    componentCommentList={componentCommentList}
                    componentIndex={componentIndex}
                    {...customProps}
                />
            )}
            {componentList?.length > 0 && (
                <CustomList
                    componentList={componentList}
                    componentIndex={componentIndex}
                    {...customProps}
                />
            )}
            {componentPhrase && (
                <CustomPhrase
                    phrase={componentPhrase}
                    componentIndex={componentIndex}
                    {...customProps}
                />
            )}
            {componentAnnexure && (
                <CustomAnnexure
                    annexure={componentAnnexure}
                    componentIndex={componentIndex}
                    {...customProps}
                />
            )}
            {componentDialog && (
                <CustomDialog
                    componentDialog={componentDialog}
                    componentIndex={componentIndex}
                    {...customProps}
                />
            )}
        </Fragment>
    )
}

export default AnswerContent;
