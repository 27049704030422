import React, { useCallback } from 'react';
import CheckBoxLabels from "./CheckBoxLabels";
import {useDispatch, useSelector} from "react-redux";
import {addDynamicSectionReq, dynamicSectionRemoveReq} from "../../store/Matters/mattersSlice";
import {useParams} from "react-router-dom";
import CustomAccordion from "../GlobalComponents/CustomAccordion";

const AccordionUsage = ({ dynamic_sections }) => {
    const acts = useSelector(state => state.matters.acts) || []
    const { matterId } = useParams();
    const dispatch = useDispatch();
    const handleAddDynamicSection = useCallback((checked, actId) => {
        if (checked) {
            dispatch(addDynamicSectionReq({
                act_id: actId,
                matter_id: +matterId
            }))
        } else {
            const actInfoDynamic = dynamic_sections.map(elem => {
                return {
                    section_id: elem.id,
                    act_id: elem.act?.id,
                    act_title: elem.act?.title
                }
            })
            const sectionIdInfo = actInfoDynamic.find(info => info.act_id === actId)
                ?.section_id
            dispatch(dynamicSectionRemoveReq({
                actId: sectionIdInfo,
                matterId
            }))
        }
    }, [dispatch, dynamic_sections, matterId])
    const actIdArray = dynamic_sections.map(section => section.act.id)
    return (
        <CustomAccordion
            title="<p>Acts:</p>"
            panelId="1"
            detailsSX={{ display: "flex", flexWrap: "wrap", gap: "0 20px" }}
        >
            {acts.map(({ title, id }) => {
                return <CheckBoxLabels
                    key={id}
                    title={title}
                    id={id}
                    onClick={handleAddDynamicSection}
                    checked={actIdArray.some(ii => ii === id)}
                />
            })}
        </CustomAccordion>
    );
}

export default AccordionUsage