import React, {Fragment, useCallback, useState} from "react";
import Stack from "@mui/material/Stack";
import CustomAccordion from "../../GlobalComponents/CustomAccordion";
import { contentSectionHandleAction } from "../../../store/Matters/mattersSlice";
import { useDispatch, useSelector } from "react-redux";
import AnswerContent from "./AnswerContent";
import ShowYesNoBtn from "./ShowYesNoBtn";
import reminderElements from "./ReminderElements";
import ReminderModal from "../../GlobalComponents/ReminderModal";

const SpecialQuestions = ({ specialQuestions, sectionId, staticSection = false, actId }) => {
    const [selectActId, setSelectActId] = useState(null);
    const [selectChildActId, setSelectChildActId] = useState(null);
    const dispatch = useDispatch();
    const {
        actID: showActId = null,
        questionID: showQuestionId = null,
        show: showQuestions = false
    } = useSelector(state => state.matters.showQuestions) || {}
    const { open: reminderOpened } = useSelector(state => state.matters.reminderOpened);

    const showSectionsToggle = useCallback(({showBtn, id}) => {
        if (showBtn) {
            return sectionId !== showActId || showQuestionId !== id || !showQuestions
        } else {
            return sectionId === showActId && showQuestionId === id && showQuestions
        }
    }, [sectionId, showActId, showQuestionId, showQuestions])

    const handleAnswerUpdate = (answer) => {
        dispatch(contentSectionHandleAction(answer[0] ? [{
            ...answer[0],
            content: JSON.parse(answer[0]?.content)
        }] : [{content: {list: [{ note: { id: Math.random(), value: "" }}]}}]))}

    return <Fragment>
        {specialQuestions.map((
            {
                description: parentDesc,
                id,
                questions: specialChildQuestion = [],
                title,
                answer,
                has_reminders,
                reminders: remindersParent,
                position: parentPosition,
                is_user_defined: is_user_defined_parent,
                has_yes_no
            }) => {
        const [{ id: answerIdParent = null, content: stringifySchemeParent, going_next: goingNextParent = 1 } = {}] = answer || []
        const answerContentSchemeParent = stringifySchemeParent
            ? JSON.parse(stringifySchemeParent).list
            : [{ note: { id: Math.random(), value: "" } }]

        const customParentProps = {
            answerId: answerIdParent,
            answerList: answerContentSchemeParent,
            questionId: id,
            actId: showActId,
            going_next: goingNextParent,
            sectionId,
            staticSection: staticSection
        }
        const reminderAttributesParent = {
            dispatch,
            childId: id,
            sectionId,
            has_reminders: has_reminders,
            going_next: goingNextParent,
            showActId,
            answerId: answerIdParent,
            answerContentScheme: answerContentSchemeParent,
            showSpecialReminders: true,
            staticSection,
            is_user_defined: is_user_defined_parent
        }
        const handleAccordionChange = (event, isExpanded) => {
            if (isExpanded) {
                setSelectActId(id)
            } else {
                setSelectActId(null)
            }
        };
        return <div key={id} style={{ position: "relative" }}><CustomAccordion
            title={title}
            panelId={id}
            borderSX={{border: "1px solid #E3E6EF"}}
            onChange={handleAccordionChange}
            expanded={id !== selectActId ? {expanded: false} : {expanded: true}}
            toolTipDesc={parentDesc}
            reminders={remindersParent}
            componentInfo={{
                question_id: id,
                [staticSection ? "static_section_id": "dynamic_section_id"]: sectionId,
            }}
            position={`${parentPosition}. `}
            showQuestionBtn={!(!!staticSection)}
            specialQuestion
            actId={actId}
        >
            {selectActId === id ? (
                <Fragment>
                    {specialChildQuestion.length > 0 ? (
                        <Fragment>
                            <ShowYesNoBtn
                                has_yes_no={has_yes_no}
                                showSectionsToggle={showSectionsToggle}
                                childQuestions={specialChildQuestion}
                                id={id}
                                sectionId={sectionId}
                            />
                            {showSectionsToggle({showBtn: false, id}) ? (
                                <Stack>
                                    {specialChildQuestion.map(({
                                                                   id: childId,
                                                                   description,
                                                                   answer,
                                                                   title,
                                                                   reminders,
                                                                   has_reminders: hasRemindersParent,
                                                                   position,
                                                                   is_user_defined
                                                               }) => {
                                        const [{
                                            id: answerId = null,
                                            content: stringifyScheme,
                                            going_next = 1
                                        } = {}] = answer || []
                                        const answerContentScheme = stringifyScheme
                                            ? JSON.parse(stringifyScheme).list
                                            : [{note: {id: Math.random(), value: ""}}]
                                        const handleAccordionChange2 = (event, isExpanded) => {
                                            if (isExpanded) {
                                                setSelectChildActId(childId)
                                                handleAnswerUpdate(answer)
                                            } else {
                                                setSelectChildActId(null)
                                            }
                                        };
                                        const customProps = {
                                            answerId,
                                            answerList: answerContentScheme,
                                            questionId: selectChildActId,
                                            actId: showActId,
                                            parentQuestionId: showQuestionId,
                                            going_next: going_next,
                                            sectionId,
                                            staticSection: staticSection
                                        }
                                        const reminderAttributes = {
                                            dispatch,
                                            childId,
                                            sectionId,
                                            showQuestionId,
                                            reminders,
                                            going_next,
                                            showActId,
                                            answerId,
                                            answerContentScheme,
                                            has_reminders: hasRemindersParent,
                                            showSpecialReminders: true,
                                            staticSection,
                                            is_user_defined
                                        }
                                        return (
                                            <div key={childId} style={{position: "relative"}}>
                                                <CustomAccordion
                                                    title={title}
                                                    panelId={childId}
                                                    borderSX={{
                                                        border: "1px solid #E3E6EF",
                                                        backgroundColor: "grey.200"
                                                    }}
                                                    detailsSX={{backgroundColor: "common.white"}}
                                                    onChange={handleAccordionChange2}
                                                    expanded={selectChildActId !== childId ? {expanded: false} : {expanded: true}}
                                                    toolTipDesc={description}
                                                    reminders={reminders}
                                                    componentInfo={{
                                                        question_id: selectChildActId,
                                                        [staticSection ? "static_section_id": "dynamic_section_id"]: sectionId,
                                                        parent_question_id: showQuestionId,
                                                    }}
                                                    position={`${position}. `}
                                                    showQuestionBtn={!(!!staticSection)}
                                                    specialQuestion
                                                    actId={actId}
                                                >
                                                    {selectChildActId === childId && answerContentScheme.map((elem, componentIndex) => (
                                                        <AnswerContent {...elem} componentIndex={componentIndex}
                                                                       customProps={customProps}/>
                                                    ))}
                                                    {reminderElements(reminderAttributes).button}
                                                </CustomAccordion>
                                                {reminderElements(reminderAttributes).accRightPosition}
                                            </div>
                                        )
                                    })}
                                </Stack>
                            ) : null}
                        </Fragment>
                    ) : <Fragment>
                            {answerContentSchemeParent.map((elem, componentIndex) => (
                                <AnswerContent {...elem} componentIndex={componentIndex} customProps={customParentProps}/>
                            ))}
                            {reminderElements(reminderAttributesParent).button}
                        </Fragment>
                    }
                </Fragment>
            ) : null}
        </CustomAccordion>
            {reminderElements(reminderAttributesParent).accRightPosition}
        </div>
    })}
        {reminderOpened && <ReminderModal />}
    </Fragment>

}

export default SpecialQuestions;
