import CustomTaggable from "./CustomTaggable";
import React, {useCallback, useEffect} from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {debounce, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomSelect from "./CustomSelect";
import {useDispatch} from "react-redux";
import CustomAnswerList from "./CustomAnswerList";
import {requestMemberComponentsSync} from "../../usefuls/helpers";
import {useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";

const CustomDialog = ({ members = [], sectionId, componentIndex, componentDialog , answerList, answerId, going_next, staticSection, ...customProps }) => {
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    const addDialogItem = useCallback(() => {
        const updatedData = [...answerList];
        updatedData[componentIndex].dialog.dialogValues.push({ whoSad: "", whatSad: "" })
        requestMemberComponentsSync(
            {
                customProps: {sectionId, ...customProps},
                matterId,
                updatedData,
                answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [answerId, answerList, componentIndex, customProps, dispatch, going_next, matterId, sectionId, staticSection])

    useEffect(() => {
        setValue("dialog", componentDialog.dialogValues)
    }, [componentDialog.dialogValues, setValue]);

    const removeDialogItem = useCallback((index) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].dialog.dialogValues =
            updatedData[componentIndex].dialog.dialogValues
                .filter((_, filteredIndex) => index !== filteredIndex)
        requestMemberComponentsSync(
            {
                customProps: {sectionId, ...customProps},
                matterId,
                updatedData,
                answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [answerId, answerList, componentIndex, customProps, dispatch, going_next, matterId, sectionId, staticSection])
    const debouncedOnChange = debounce(({ target: { id: whatSadId, value: whatSadVal } }) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].dialog.dialogValues[+whatSadId].whatSad = whatSadVal
            requestMemberComponentsSync(
                {
                    customProps: {sectionId, ...customProps},
                    matterId,
                    updatedData,
                    answerId,
                    dispatch,
                    going_next,
                    staticSection
                }
            )
    }, 500);
    const propAttributes = {
        members: componentDialog.members,
        answerList,
        componentIndex,
        customProps: {sectionId, ...customProps},
        answerId
    }

    return (
        <Stack sx={{ marginTop: "20px" }}>
            <Typography>Dialog</Typography>
            <CustomTaggable sectionId={sectionId} forDialog {...propAttributes} staticSection={staticSection}/>
            {componentDialog.dialogValues.map((elem, index, array) => (
                <Box display="flex" alignItems="center">
                    <CustomSelect
                        {...propAttributes}
                        matterId={matterId}
                        memberIndex={index}
                        currName={elem.whoSad}
                        staticSection={staticSection}
                    />
                    <Box display="flex" alignItems="center" height="50px">
                        <Controller
                            control={control}
                            name={`dialog.${index}.whatSad`}
                            render={({field: {onChange, value, ref, onBlur}}) => (
                                <TextField
                                    id={`${index}`}
                                    name="whatSad"
                                    size="small"
                                    sx={{ ml: "10px" }}
                                    placeholder="What sad?"
                                    onChange={(e) => {
                                        onChange(e);
                                        debouncedOnChange(e)
                                    }}
                                    value={value}
                                    inputRef={ref}
                                    onBlur={onBlur}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}/>
                        <Button onClick={() => {
                            removeDialogItem(index)
                        }} sx={{ backgroundColor: "grey.300", color: "black.200", ml: "10px" }}>
                            <RemoveIcon />
                        </Button>
                        {array.length - 1 === index && (
                            <Button onClick={addDialogItem} sx={{ backgroundColor: "info.dark", color: "common.white", ml: "10px" }}>
                                +Add
                            </Button>
                        )}
                    </Box>
                </Box>
            ))}
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                {...{sectionId, ...customProps}}
            />
        </Stack>
    )
}

export default CustomDialog