import AccordionUsage from "./AccordionActs";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import StaticSections from "./StaticSections";
import DynamicSections from "./DynamicSections";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, {Fragment, useState} from "react";
import {stepButtonSXHandler} from "../../usefuls/helpers";
import { useSelector } from "react-redux";
import { useRouter } from "../../usefuls/hooks";
import GenerateModal from "./GenerateModal";

const FirstStep = () => {
    const [generateOpened, setGenerateOpened] = useState(false);
    const router = useRouter();
    const loading = useSelector(state => state.matters.actLoading);
    const { dynamic_sections = [], static_sections = [] } = useSelector(state => state.matters.matters) || {};
    const handleChangeStep = (step) => {
        router.queryPush({ step })
    }

    return (
        <Fragment>
            <AccordionUsage dynamic_sections={dynamic_sections}/>
            <Divider/>
            <Container fixed sx={{padding: "40px 0"}}>
                {loading && <div className="loader" style={{margin: "0 auto"}}/>}
                <Stack gap={2}>
                    {static_sections.map((elem, index) => (
                        <StaticSections key={index} {...elem} staticSection={true}/>
                    ))}
                    {dynamic_sections.map((elem, index) => (
                        <DynamicSections key={index} fullActInfo={elem}/>
                    ))}
                </Stack>
                <Box sx={{ float: "right", display: "flex" }}>
                    <Button
                        sx={stepButtonSXHandler({backgroundColor: "success.dark" })}
                        onClick={() => setGenerateOpened(true)}
                    >
                        Generate
                    </Button>
                    <Button
                        sx={stepButtonSXHandler({ marginLeft: { marginLeft: "30px"} })}
                        onClick={() => handleChangeStep(2)}
                    >
                        Next Step
                    </Button>
                </Box>
            </Container>
            <GenerateModal generateOpened={generateOpened} setGenerateOpened={setGenerateOpened} />
        </Fragment>
    )
}

export default FirstStep