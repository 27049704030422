import React, { Fragment, useEffect } from "react";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import {Controller, useForm} from "react-hook-form";
import { editQuestionWithIdAction, updateQuestionReq } from "../../../store/Matters/mattersSlice";

const NewQuestionFields = ({ questionId, defaultValues, ...addQuestionBody }) => {
    const dispatch = useDispatch();
    const {
        control,
        setValue,
        handleSubmit,
        watch,
        formState: { isSubmitSuccessful }
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    useEffect(() => {
        if (defaultValues) {
            setValue("question", defaultValues)
        }
    }, [defaultValues, setValue]);

    const onSubmit = (data) => {
        let label = {}
        if (!!data.question.title) {
            label.title = data.question.title
        }
        if (!!data.question.description) {
            label.description = data.question.description
        }
        dispatch(updateQuestionReq({
            questionId,
            ...label,
            ...addQuestionBody,
        }))
        dispatch(editQuestionWithIdAction(null))
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    return (
        <Fragment>
            {(!isSubmitSuccessful || !(!!watch().question.title)) && (
                <Controller
                    control={control}
                    name={`question.title`}
                    render={({field: {onChange, value, ref, onBlur}}) => (
                        <TextField
                            name="title"
                            sx={{ height: "70%", backgroundColor: "common.white", display: "inline" }}
                            size="small"
                            placeholder="Type question"
                            onClick={(e) => e.stopPropagation()}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                            onKeyPress={handleKeyPress}
                        />
                    )}
                />
            )}
            {(!isSubmitSuccessful || !(!!watch().question.description)) && (
                <Controller
                    control={control}
                    name={`question.description`}
                    render={({field: {onChange, value, ref, onBlur}}) => (
                        <TextField
                            name="description"
                            sx={{ height: "70%", backgroundColor: "common.white", display: "inline", ml: "20px" }}
                            size="small"
                            placeholder="Type description"
                            onClick={(e) => e.stopPropagation()}
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                            onKeyPress={handleKeyPress}
                        />
                    )}
                />
            )}
        </Fragment>
    )
}

export default NewQuestionFields;