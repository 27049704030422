import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { axiosClient as axios } from "../../requests/axiosClient";

export const storeMatterReq = createAsyncThunk(
    'matters/storeMatterReq',
    async (_, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/matters`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const getMatterByIdReq = createAsyncThunk(
    'matters/getMatterByIdReq',
    async (id, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.get(`api/matters/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const getMattersListReq = createAsyncThunk(
    'matters/getMattersListReq',
    async (queryParams, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.get(`api/matters${queryParams}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const matterDeleteReq = createAsyncThunk(
    'matters/matterDeleteReq',
    async (id, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.delete(`api/matters/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const addDynamicSectionReq = createAsyncThunk(
    'matters/addDynamicSectionReq',
    async (body, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/sections`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const dynamicSectionRemoveReq = createAsyncThunk(
    'matters/dynamicSectionRemoveReq',
    async ({ actId, matterId }, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            await axios.delete(`api/sections/${actId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return actId;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const deleteMemberReq = createAsyncThunk(
    'matters/deleteMemberReq',
    async ({memberId, sectionId}, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            await axios.delete(`api/members/${memberId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return {memberId, sectionId};
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const addMemberReq = createAsyncThunk(
    'matters/addMemberReq',
    async (body, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/members`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const answerContentCreateReq = createAsyncThunk(
    'matters/answerContentCreateReq',
    async (body, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/answers`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const answerContentUpdateReq = createAsyncThunk(
    'matters/answerContentUpdateReq',
    async ({ id, ...body }, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            await axios.put(`api/answers/${id}`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const answerContentDeleteReq = createAsyncThunk(
    'matters/answerContentDeleteReq',
    async ({id, ...body}, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            await axios.delete(`api/answers/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return {id, ...body};
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const reminderAddReq = createAsyncThunk(
    'matters/reminderAddReq',
    async (body, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/reminders`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const reminderDeleteReq = createAsyncThunk(
    'matters/reminderDeleteReq',
    async ({ id, reminder }, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            await axios.delete(`api/reminders/${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return {
                data: reminder,
                is_foreach_member: Number(!!reminder.member_id),
                remove: true
            };
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const reminderUpdateReq = createAsyncThunk(
    'matters/reminderUpdateReq',
    async ({ id, ...body }, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.put(`api/reminders/${id}`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

export const addQuestionReq = createAsyncThunk(
    'matters/addQuestionReq',
    async (body, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/questions`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const updateQuestionReq = createAsyncThunk(
    'matters/updateQuestionReq',
    async ({ questionId, position, ...body }, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.put(`api/questions/${questionId}`, body, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const deleteQuestionReq = createAsyncThunk(
    'matters/deleteQuestionReq',
    async (body , { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            await axios.delete(`api/questions/${body.id}`,{
                headers: { Authorization: `Bearer ${token}` }
            });
            return body;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const goToSecondStepReq = createAsyncThunk(
    'matters/goToSecondStepReq',
    async (id , { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } = await axios.post(`api/next-step/${id}`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const updateEditorResultReq = createAsyncThunk(
    'matters/updateEditorResultReq',
    async ({ editorId, editorContent: content } , { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            await axios.put(`api/results/${editorId}`, { content }, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const getResultsReq = createAsyncThunk(
    'matters/getResultsReq',
    async (id , { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } =  await axios.get(`api/results?matter_id=${id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);
export const getWordViewUrlReq = createAsyncThunk(
    'matters/getWordViewUrlReq',
    async (_, { rejectWithValue, getState }) => {
        try {
            const { auth: { userInfo: { access_token: token } = {} } } = getState();
            const { data } =  await axios.post(`api/overs`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return data;
        } catch (error) {
            if (error.response.status !== 200) {
                if (error.response.status === 401) {
                    localStorage.removeItem("userInfo")
                    window.location.pathname = "/signin"
                }
                return rejectWithValue(error.response.data);
            } else {
                throw error;
            }
        }
    }
);

const matterActContentUpdate = (state, action) => {
    state.loading = false;
    const { answer, data, is_foreach_member, remove = false, is_in_dynamic_section } = action.payload || {}
    const isAnswer = !!answer
    const isReminder = !!data
    const {
        dynamic_section_id,
        parent_question_id = null,
        question_id
    } = (() => {
        if (isAnswer) return answer
        if (isReminder) return data
        return {}
    })()

    const questionsHandler = (questionArray, questionTitle, eachOrSpecial) => {
        return eachOrSpecial ? questionArray.act[questionTitle].map(item => {
            if (parent_question_id) {
                if (item.id === parent_question_id) {
                    return {
                        ...item,
                        questions: item.questions.map(attr => {
                            if (attr.id === question_id) {
                                if (isAnswer) {
                                    return {
                                        ...attr,
                                        answer: [action.payload.answer]
                                    }
                                }
                                if (isReminder) {
                                    let updatedReminders = [...attr.reminders];
                                    const hasReminderItem = updatedReminders.findIndex(remItem => remItem.id === action.payload.data.id)
                                    if (hasReminderItem > -1) {
                                        if (remove) {
                                            updatedReminders = updatedReminders.filter(filterRem => filterRem.id !== action.payload.data.id)
                                        } else {
                                            updatedReminders[hasReminderItem] = action.payload.data
                                        }
                                    } else {
                                        updatedReminders.push(action.payload.data)
                                    }
                                    return {
                                        ...attr,
                                        reminders: remove ? updatedReminders.filter(filterRem => filterRem.id !== action.payload.data.id) : updatedReminders
                                    }
                                }
                                return attr;
                            }
                            return attr;
                        })
                    }
                }
            } else if (item.id === question_id) {
                if (isAnswer) {
                    return {
                        ...item,
                        answer: [action.payload.answer]
                    }
                }
                if (isReminder) {
                    let updatedReminders = [...item.reminders];
                    const hasReminderItem = updatedReminders.findIndex(remItem => remItem.id === action.payload.data.id)
                    if (hasReminderItem > -1) {
                        if (remove) {
                            updatedReminders = updatedReminders.filter(filterRem => filterRem.id !== action.payload.data.id)
                        } else {
                            updatedReminders[hasReminderItem] = action.payload.data
                        }
                    } else {
                        updatedReminders.push(action.payload.data)
                    }
                    return {
                        ...item,
                        reminders: updatedReminders
                    }
                }
                return item;
            }
            return item;
        }) : questionArray.act[questionTitle]
    }

    const dynamicSectionHandler = state.matters.dynamic_sections.map(elem => {
        if (elem.id === dynamic_section_id) {
            return {
                ...elem,
                act: {
                    ...elem.act,
                    each_member_questions: questionsHandler(elem, "each_member_questions", !!is_foreach_member),
                    special_questions: questionsHandler(elem, "special_questions", !(!!is_foreach_member))
                }
            }
        }
        return elem;
    })

    state.matters = {
        ...state.matters,
        dynamic_sections: !!is_in_dynamic_section ? dynamicSectionHandler : state.matters.dynamic_sections,
        static_sections: !(!!is_in_dynamic_section) ? state.matters.static_sections.map(section => {
            return {
                ...section,
                special_questions: section.special_questions.map(question => {
                    if (parent_question_id) {
                        if (question.id === parent_question_id) {
                            return {
                                ...question,
                                questions: question.questions.map(childQuestion => {
                                    if (childQuestion.id === question_id) {
                                        if (isAnswer) {
                                            return {
                                                ...childQuestion,
                                                answer: [action.payload.answer]
                                            }
                                        }
                                        if (isReminder) {
                                            let updatedReminders = [...childQuestion.reminders];
                                            const hasReminderItem = updatedReminders.findIndex(remItem => remItem.id === action.payload.data.id)
                                            if (hasReminderItem > -1) {
                                                if (remove) {
                                                    updatedReminders = updatedReminders.filter(filterRem => filterRem.id !== action.payload.data.id)
                                                } else {
                                                    updatedReminders[hasReminderItem] = action.payload.data
                                                }
                                            } else {
                                                updatedReminders.push(action.payload.data)
                                            }
                                            return {
                                                ...childQuestion,
                                                reminders: remove ? updatedReminders.filter(filterRem => filterRem.id !== action.payload.data.id) : updatedReminders
                                            }
                                        }
                                        return childQuestion;
                                    }
                                    return childQuestion;
                                })
                            }
                        }
                        return question
                    } else if (question.id === question_id) {
                        if (isAnswer) {
                            return {
                                ...question,
                                answer: [action.payload.answer]
                            }
                        }
                        if (isReminder) {
                            let updatedReminders = [...question.reminders];
                            const hasReminderItem = updatedReminders.findIndex(remItem => remItem.id === action.payload.data.id)
                            if (hasReminderItem > -1) {
                                if (remove) {
                                    updatedReminders = updatedReminders.filter(filterRem => filterRem.id !== action.payload.data.id)
                                } else {
                                    updatedReminders[hasReminderItem] = action.payload.data
                                }
                            } else {
                                updatedReminders.push(action.payload.data)
                            }
                            return {
                                ...question,
                                reminders: updatedReminders
                            }
                        }
                        return question;
                    }
                    return question
                })
            };
        }) : state.matters.static_sections
    }
}

const rejectedManagerFunc = (state, action) => {
    state.loading = false;
    state.alertMessage = { message: action.payload, severity: "error" }
    state.openToast = true;
    state.error = action.error?.message;
}
const pendingManagerFunc = (state) => {
    state.loading = true;
    state.error = null;
}


const initialState = {
    openToast: false,
    loading: false,
    actLoading: false,
    alertMessage: null,
    mattersList: null,
    matters: null,
    membersScheme: null,
    acts: null,
    showQuestions: null,
    answerContentScheme: [{
        dynamic_section_id: null,
        id: null,
        parent_question_id: null,
        question_id: null,
        content: {
            list: [{
                note: { id: Math.random(), value: "" }, // { id: number, value: string },
                commentList: null, // Array<{ id: number, value: string }>,
                list: null, // Array<{ id: number, value: string }>,
                phrase: null, // { id: number, value: string },
                dialog: null, // { id: number, members: Array<{id: number, name: string}>, dialogValues: Array<{whoSad: string, whatSad: string}>}
                annexure: null // { id: number, value: { lettersInpVal: string, titleVal: string } }
            }]
        },
    }],
    reminderOpened: false,
    reminderInfo: null,
    editQuestionInfo: null,
    editorResults: null,
    wordViewUrl: null,
}

export const mattersSlice = createSlice({
    name: 'matters',
    initialState,
    reducers: {
        reminderOpenAction: (state, action) => {
            state.reminderOpened = {
                open: true,
                ...action.payload
            }
        },
        reminderCloseAction: (state) => {
            state.reminderOpened = { open: false }
        },
        reminderModalInfoAction: (state, action) => {
            state.reminderInfo = action.payload
        },
        contentSectionHandleAction: (state, action) => {
            state.answerContentScheme = action.payload
        },
        showQuestionsAction: (state, action) => {
            state.showQuestions = action.payload
        },
        closeToastAction: (state) => {
            state.openToast = false;
        },
        editQuestionWithIdAction: (state, action) => {
            state.editQuestionInfo = action.payload
        },
        answerUpdateAction: (state, action) => {
            const {
                data: {
                    dynamic_section_id,
                    parent_question_id,
                    question_id,
                    static_section_id,
                    content: answerContentList
                },
                is_foreach_member,
                is_in_dynamic_section,
            } = action.payload || {}
            const questionsKey = is_foreach_member ? "each_member_questions" : "special_questions"
            const eachMemberQuestionsHandle = (content) => {
                if (is_foreach_member) {
                    return { each_member_questions: content }
                }
                return { special_questions: content }
            }

            const questionIdsHandle = questionsArr => questionsArr.map(question => {
                if (question.id === parent_question_id) {
                    return {
                        ...question,
                        questions: question.questions.map(childQuestion => {
                            const { id: qId, answer: [ answerContent ] } = childQuestion
                            if (qId === question_id) {
                                return {
                                    ...childQuestion,
                                    answer: [{
                                        ...answerContent,
                                        content: answerContentList
                                    }]
                                }
                            }
                            return childQuestion
                        })
                    }
                } else if (question.id === question_id) {
                    const { id: qId, answer: [ answerContent ] } = question
                    if (qId === question_id) {
                        return {
                            ...question,
                            answer: [{
                                ...answerContent,
                                content: answerContentList
                            }]
                        }
                    }
                    return question
                }
                return question;
            })

            if (is_in_dynamic_section) {
                state.matters.dynamic_sections = state.matters.dynamic_sections.map(section => {
                    const { id: sectionId, act: sectionAct } = section;
                    if (sectionId === dynamic_section_id) {
                        return {
                            ...section,
                            act: {
                                ...sectionAct,
                                ...eachMemberQuestionsHandle(questionIdsHandle(sectionAct[questionsKey]))
                            }
                        }
                    }
                    return section;
                })
            } else {
                state.matters.static_sections = state.matters.static_sections.map(section => {
                    if (section.id === static_section_id) {
                        return { ...section, special_questions: questionIdsHandle(section.special_questions) };
                    }
                    return section
                })
            }
        },
        AIModalOpenAction: (state, action) => {
            state.openedAIModal = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(storeMatterReq.pending, pendingManagerFunc)
            .addCase(storeMatterReq.fulfilled, (state, action) => {
                state.loading = false;
                state.alertMessage = { message: action.payload, severity: "success" }
                state.openToast = true;
            })
            .addCase(storeMatterReq.rejected, rejectedManagerFunc)
            .addCase(getMattersListReq.pending, pendingManagerFunc)
            .addCase(getMattersListReq.fulfilled, (state, action) => {
                state.loading = false;
                state.mattersList = action.payload;
            })
            .addCase(getMattersListReq.rejected, rejectedManagerFunc)
            .addCase(matterDeleteReq.pending, pendingManagerFunc)
            .addCase(matterDeleteReq.fulfilled, (state, action) => {
                state.loading = false;
                state.mattersList = action.payload
            })
            .addCase(matterDeleteReq.rejected, rejectedManagerFunc)
            .addCase(getMatterByIdReq.pending, (state) => {
                state.loading = true;
                state.actLoading = true;
                state.error = null;
            })
            .addCase(getMatterByIdReq.fulfilled, (state, action) => {
                state.loading = false;
                state.actLoading = false;
                state.matters = action.payload.matter;
                state.acts = action.payload.acts
            })
            .addCase(getMatterByIdReq.rejected, (state, action) => {
                state.loading = false;
                state.actLoading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(addDynamicSectionReq.pending, (state) => {
                state.loading = true;
                state.actLoading = true;
                state.error = null;
            })
            .addCase(addDynamicSectionReq.fulfilled, (state, action) => {
                state.loading = false;
                state.actLoading = false;
                const updatedDynamicSections = [...state.matters.dynamic_sections]
                updatedDynamicSections.push(action.payload.data)
                state.matters = {
                    ...state.matters,
                    dynamic_sections: updatedDynamicSections
                }
            })
            .addCase(addDynamicSectionReq.rejected, (state, action) => {
                state.loading = false;
                state.actLoading = false;
                state.alertMessage = { message: action.payload, severity: "error" }
                state.openToast = true;
                state.error = action.error?.message;
            })
            .addCase(dynamicSectionRemoveReq.pending, pendingManagerFunc)
            .addCase(dynamicSectionRemoveReq.fulfilled, (state, action) => {
                state.loading = false;
                const updatedDynamicSections = [...state.matters.dynamic_sections]
                const filteredSections = updatedDynamicSections.filter(filteredSection => filteredSection.id !== action.payload)
                state.matters = {
                    ...state.matters,
                    dynamic_sections: filteredSections
                }
            })
            .addCase(dynamicSectionRemoveReq.rejected, rejectedManagerFunc)
            .addCase(deleteMemberReq.pending, pendingManagerFunc)
            .addCase(deleteMemberReq.fulfilled, (state, action) => {
                state.loading = false;
                state.matters = {
                    ...state.matters,
                    dynamic_sections: state.matters.dynamic_sections.map(elem => {
                        if (elem.id === action.payload.sectionId) {
                            const updatedMembers = [...elem.members].filter(ii => ii.id !== action.payload.memberId)
                            return {
                                ...elem,
                                members: updatedMembers
                            }
                        }
                        return elem
                    })
                };
            })
            .addCase(deleteMemberReq.rejected, rejectedManagerFunc)
            .addCase(addMemberReq.pending, pendingManagerFunc)
            .addCase(addMemberReq.fulfilled, (state, action) => {
                state.loading = false;
                state.matters = {
                    ...state.matters,
                    dynamic_sections: state.matters.dynamic_sections.map(elem => {
                        if (elem.id === action.payload.data.dynamic_section_id) {
                            const updatedMembers = [...elem.members]
                            updatedMembers.push(action.payload.data)
                            return {
                                ...elem,
                                members: updatedMembers
                            }
                        }
                        return elem
                    })
                }
            })
            .addCase(addMemberReq.rejected, rejectedManagerFunc)
            .addCase(answerContentCreateReq.pending, pendingManagerFunc)
            .addCase(answerContentCreateReq.fulfilled, matterActContentUpdate)
            .addCase(answerContentCreateReq.rejected, rejectedManagerFunc)
            .addCase(answerContentUpdateReq.pending, pendingManagerFunc)
            .addCase(answerContentUpdateReq.fulfilled,  (state) => {
                state.loading = false
            })
            .addCase(answerContentDeleteReq.rejected, rejectedManagerFunc)
            .addCase(answerContentDeleteReq.pending, pendingManagerFunc)
            .addCase(answerContentDeleteReq.fulfilled,  (state) => {
                state.loading = false;
            })
            .addCase(answerContentUpdateReq.rejected, rejectedManagerFunc)
            .addCase(reminderAddReq.pending, pendingManagerFunc)
            .addCase(reminderAddReq.fulfilled, matterActContentUpdate)
            .addCase(reminderAddReq.rejected, rejectedManagerFunc)
            .addCase(reminderDeleteReq.pending, pendingManagerFunc)
            .addCase(reminderDeleteReq.fulfilled, matterActContentUpdate)
            .addCase(reminderDeleteReq.rejected, rejectedManagerFunc)
            .addCase(reminderUpdateReq.pending, pendingManagerFunc)
            .addCase(reminderUpdateReq.fulfilled, matterActContentUpdate)
            .addCase(reminderUpdateReq.rejected, rejectedManagerFunc)
            .addCase(addQuestionReq.pending, pendingManagerFunc)
            .addCase(addQuestionReq.fulfilled, (state, action) => {
                state.loading = false;
                const { data: { dynamic_section_id, for_each_member, ...questionBody } } = action.payload;
                const questionKeyHandle = { true: "each_member_questions", false: "special_questions" }[!!for_each_member];

                state.matters = {
                    ...state.matters,
                    dynamic_sections: state.matters.dynamic_sections.map(section => {
                        if (section.id === dynamic_section_id) {
                            if (!(!!questionBody?.parent_question_id)) {
                                const updateQuestions = [...section.act[questionKeyHandle]]
                                const insertIndex = section.act[questionKeyHandle].findIndex(i => i.position >= questionBody.position);
                                const sameIndex = section.act[questionKeyHandle].findIndex(i => questionBody.position === i.position + 1);
                                if (sameIndex > -1) {
                                    updateQuestions.splice(sameIndex + 1, 0, questionBody);
                                    for (let i = sameIndex + 2; i < updateQuestions.length; i++) {
                                        //TODO!!! after check this issue will remove this comment
                                        // if (updateQuestions[i].position >= questionBody.position) {
                                        if (updateQuestions[i].position === (updateQuestions[i - 1].position || questionBody.position + 1)) {
                                            updateQuestions[i].position++;
                                        }
                                    }
                                    return {
                                        ...section,
                                        act: {
                                            ...section.act,
                                            [questionKeyHandle]: updateQuestions
                                        }
                                    }
                                }
                                if (insertIndex > -1 && sameIndex === -1) {
                                    updateQuestions.splice(insertIndex, 0, questionBody);
                                    for (let i = insertIndex + 1; i < updateQuestions.length; i++) {
                                        if (updateQuestions[i].position === (updateQuestions[i - 1].position || questionBody.position + 1)) {
                                            updateQuestions[i].position++;
                                        }
                                    }
                                    return {
                                        ...section,
                                        act: {
                                            ...section.act,
                                            [questionKeyHandle]: updateQuestions
                                        }
                                    }
                                }
                            }
                             return {
                                 ...section,
                                 act: {
                                     ...section.act,
                                     [questionKeyHandle]: section.act[questionKeyHandle].map((question) => {
                                         if (
                                             !!questionBody?.parent_question_id
                                             && questionBody?.parent_question_id === question.id
                                         ) {
                                             const updateQuestions = [...question.questions]
                                             const insertIndex = question.questions.findIndex(i => i.position >= questionBody.position);
                                             const sameIndex = question.questions.findIndex(i => questionBody.position === i.position + 1);
                                             if (sameIndex > -1) {
                                                 updateQuestions.splice(sameIndex + 1, 0, questionBody);
                                                 for (let i = sameIndex + 2; i < updateQuestions.length; i++) {
                                                     if (updateQuestions[i].position === (updateQuestions[i - 1].position || questionBody.position + 1)) {
                                                         updateQuestions[i].position++;
                                                     }
                                                 }
                                                 return {
                                                     ...question,
                                                     questions: updateQuestions,
                                                 };
                                             }
                                             if (insertIndex > -1 && sameIndex === -1) {
                                                 updateQuestions.splice(insertIndex, 0, questionBody);
                                                 for (let i = insertIndex + 1; i < updateQuestions.length; i++) {
                                                     if (updateQuestions[i].position === (updateQuestions[i - 1].position || questionBody.position + 1)) {
                                                        updateQuestions[i].position++;
                                                     }
                                                 }
                                                 return {
                                                     ...question,
                                                     questions: updateQuestions,
                                                 };
                                             }
                                             return question;
                                         }
                                         return question;
                                     })
                                 }
                             }
                        }
                        return section
                    })
                }
            })
            .addCase(addQuestionReq.rejected, rejectedManagerFunc)
            .addCase(updateQuestionReq.pending, pendingManagerFunc)
            .addCase(updateQuestionReq.fulfilled, (state, action) => {
                state.loading = false;
                const { data: { dynamic_section_id, for_each_member, ...questionBody } } = action.payload;
                const questionKeyHandle = { true: "each_member_questions", false: "special_questions" }[!!for_each_member];

                state.matters = {
                    ...state.matters,
                    dynamic_sections: state.matters.dynamic_sections.map(section => {
                        if (section.id === dynamic_section_id) {
                            return {
                                ...section,
                                act: {
                                    ...section.act,
                                    [questionKeyHandle]: section.act[questionKeyHandle].map((question) => {
                                        if (
                                            !!questionBody?.parent_question_id
                                            && questionBody?.parent_question_id === question.id
                                        ) {
                                            return {
                                                ...question,
                                                questions: question.questions.map(childQuestion => {
                                                    if (childQuestion.id === questionBody.id) {
                                                        return questionBody
                                                    }
                                                    return childQuestion;
                                                })
                                            };
                                        }
                                        if (question.id === questionBody.id) {
                                            return questionBody;
                                        }
                                        return question;
                                    })
                                }
                            }
                        }
                        return section
                    })
                }
            })
            .addCase(updateQuestionReq.rejected, rejectedManagerFunc)
            .addCase(deleteQuestionReq.pending, pendingManagerFunc)
            .addCase(deleteQuestionReq.fulfilled, (state, action) => {
                state.loading = false;
                const { id, parent_question_id, for_each_member } = action.payload;
                const questionKeyHandle = { true: "each_member_questions", false: "special_questions" }[!!for_each_member];

                state.matters = {
                    ...state.matters,
                    dynamic_sections: state.matters.dynamic_sections.map(section => {
                        if (!(!!parent_question_id)) {
                            const updateQuestions = [...section.act[questionKeyHandle]]
                            const filteredQuestions = updateQuestions.filter(elem => elem.id !== id)
                            return {
                                ...section,
                                act: {
                                    ...section.act,
                                    [questionKeyHandle]: filteredQuestions
                                }
                            }
                        }
                        return {
                            ...section,
                            act: {
                                ...section.act,
                                [questionKeyHandle]: section.act[questionKeyHandle].map((question) => {
                                    if (!!parent_question_id && parent_question_id === question.id) {
                                        const updatedQuestions = [...question.questions].filter(childQuestion => childQuestion.id !== id)
                                        return { ...question, questions: updatedQuestions };
                                    }
                                    return question;
                                })
                            }
                        }
                    })
                }
            })
            .addCase(deleteQuestionReq.rejected, rejectedManagerFunc)
            .addCase(goToSecondStepReq.rejected, rejectedManagerFunc)
            .addCase(goToSecondStepReq.pending, pendingManagerFunc)
            .addCase(goToSecondStepReq.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getResultsReq.rejected, rejectedManagerFunc)
            .addCase(getResultsReq.pending, pendingManagerFunc)
            .addCase(getResultsReq.fulfilled, (state, action) => {
                state.loading = false;
                state.editorResults = action.payload.results
            })
            .addCase(updateEditorResultReq.rejected, rejectedManagerFunc)
            .addCase(updateEditorResultReq.pending, pendingManagerFunc)
            .addCase(updateEditorResultReq.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(getWordViewUrlReq.rejected, rejectedManagerFunc)
            .addCase(getWordViewUrlReq.pending, pendingManagerFunc)
            .addCase(getWordViewUrlReq.fulfilled, (state, action) => {
                state.loading = false;
                state.wordViewUrl = action.payload
            })
    },
})

export const {
    closeToastAction,
    showQuestionsAction,
    contentSectionHandleAction,
    reminderOpenAction,
    reminderCloseAction,
    reminderModalInfoAction,
    editQuestionWithIdAction,
    answerUpdateAction,
    AIModalOpenAction,
} = mattersSlice.actions

export default mattersSlice.reducer