import React, {Fragment} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {TextField, Tooltip} from "@mui/material";
import QuestionNoteAlert from "./QuestionNoteAlert";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {addQuestionReq} from "../../store/Matters/mattersSlice";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import NewQuestionFields from "../Matters/DynamicSections/NewQuestionFields";

const CustomAccordion = (
    {
        title,
        panelId,
        children,
        detailsSX = {},
        borderSX = {},
        onChange,
        expanded,
        toolTipDesc,
        reminders = [],
        componentInfo,
        position = "",
        showQuestionBtn = false,
        memberID = null,
        specialQuestion,
        actId
    }
) => {
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const { parent_question_id: forEditParentId = null } = componentInfo || {};
    const addQuestionBody = {
        position: Number(position.split(". ")[0]) + 1,
        dynamic_section_id: componentInfo?.dynamic_section_id,
        is_dynamic: true,
        matter_id: +matterId,
        act_id: actId,
        for_each_member: !(!!specialQuestion),
        ...(!!forEditParentId ? { parent_question_id: forEditParentId } : {})
    }
    const {
        member_id: editMember_id = null,
        parent_question_id: editParentQuestionId = null,
        dynamic_section_id: editDynamicSectionId = null,
        question_id: editQuestionId = null
    } = useSelector(state => state.matters.editQuestionInfo) || {}
    return (
        <Accordion sx={borderSX} {...expanded} onChange={onChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${panelId}-content`}
                id={`panel${panelId}-header`}
                sx={{ flexDirection: 'row-reverse' }}
            >
                <Box pr="150px">
                    {position}
                    {!!title && !(
                        editMember_id === memberID
                        && editParentQuestionId === forEditParentId
                        && editDynamicSectionId === componentInfo?.dynamic_section_id
                        && editQuestionId === componentInfo?.question_id
                    ) ? (
                        <Box sx={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: title }} />
                    ) : (
                        <NewQuestionFields {...addQuestionBody} questionId={panelId} defaultValues={{
                            title: title ?? "",
                            description: toolTipDesc ?? ""
                        }} />
                    )}
                    {toolTipDesc && (
                        <Tooltip title={toolTipDesc}>
                            <InfoRoundedIcon sx={{ ml: "2px", position: "relative", top: "5px", color: "grey.300", display: "inline-block" }}/>
                        </Tooltip>
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={detailsSX}>
                {children}
                {reminders?.length > 0 && (specialQuestion || reminders?.some(({ member_id }) => member_id === memberID)) && (
                    <Fragment>
                        <Divider sx={{ mt: "15px", mb: "15px" }}/>
                        <Typography mb="10px">Note List</Typography>
                        <Box display="flex" gap="20px" flexWrap="wrap">
                            {reminders.map((elem, index) => {
                                if (elem.member_id === memberID || specialQuestion) {
                                    return <QuestionNoteAlert key={index} {...elem} componentInfo={componentInfo} />
                                }
                                return null
                            })}
                        </Box>
                    </Fragment>
                )}
                {showQuestionBtn && (
                    <Fragment>
                        <Divider sx={{ mt: "15px", mb: "15px" }}/>
                        <Box display="flex" justifyContent="center">
                            <Button sx={{ backgroundColor: "grey.300", color: "black.300", p: "8px 20px", borderRadius: "4px" }}
                                onClick={() => dispatch(addQuestionReq({
                                    title: "",
                                    description: "",
                                    is_user_defined: 1,
                                    ...addQuestionBody
                                }))}
                            >
                                +Add Question
                            </Button>
                        </Box>
                    </Fragment>
                )}
            </AccordionDetails>
        </Accordion>
    );
}

export default CustomAccordion;