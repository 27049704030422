import React, {useRef} from 'react';
import {Editor} from "@tinymce/tinymce-react";
import {useDispatch} from "react-redux";
import {updateEditorResultReq} from "../../store/Matters/mattersSlice";

const TextEditor = ({ content, id }) => {
    const editorRef = useRef(null);
    const dispatch = useDispatch();

    const handleEditorChange = () => {
        if (editorRef.current) {
            const body = {
                editorId: +editorRef.current.id,
                editorContent: editorRef.current.getContent()
            }
            dispatch(updateEditorResultReq(body))
        }
    };
    return (
        <Editor
            id={id}
            apiKey='pkzcwjggpmq1o4dpzlno6mh8v3aliig0lyeg5sdb1d5g9ja5'
            onInit={(_evt, editor) => editorRef.current = editor}
            initialValue={content}
            init={{
                height: 300,
                menubar: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                toolbar: 'formatselect | bold italic | blocks | formatparagraph | alignleft aligncenter alignright alignjustify'
        }}
            onChange={handleEditorChange}
        />
    )
}

export default TextEditor;