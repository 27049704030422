import React, {Fragment, useEffect, useState} from 'react';
import LabelStepper from "./StepsLayout";
import Stack from "@mui/material/Stack";
import {Card} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import {useParams} from "react-router-dom";
import { useDispatch } from "react-redux";
import {getMatterByIdReq, getResultsReq, getWordViewUrlReq} from "../../store/Matters/mattersSlice";
import Typography from "@mui/material/Typography";
import TermsModal from "./TermsModal";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

const EditMatter = () => {
    const { matterId } = useParams();
    const dispatch = useDispatch();
    const [termsOpened, setTermsOpened] = useState(false);
    const currentStep = +window.location.search.split("?step=")[1]

    useEffect(() => {
        if (currentStep === 1) {
            dispatch(getMatterByIdReq(matterId))
        } else if (currentStep === 2) {
            dispatch(getResultsReq(matterId))
        } else if (currentStep === 3) {
            dispatch(getWordViewUrlReq())
        }
    }, [dispatch, matterId, currentStep]);

    return (
        <Fragment>
            <Stack gap={5}>
                <LabelStepper/>
                {currentStep === 1 && (
                    <Typography
                        ml="15px"
                        mb="25px"
                        sx={{textDecoration: "underline", cursor: "pointer"}}
                        maxWidth="max-content"
                        onClick={() => setTermsOpened(true)}
                    >
                        Interview Guidelines.
                    </Typography>
                )}
            </Stack>
            <Card sx={{pt: "20px", pb: "20px", pr: "20px"}}>
                <TableContainer sx={{overflow: 'unset'}}>
                    {currentStep === 1 && <FirstStep />}
                    {currentStep === 2 && <SecondStep />}
                    {currentStep === 3 && <ThirdStep />}
                </TableContainer>
            </Card>
            <TermsModal setTermsOpened={setTermsOpened} termsOpened={termsOpened}/>
        </Fragment>
    );
};

export default EditMatter;
