import Box from "@mui/material/Box";
import {debounce, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import CustomAnswerList from "./CustomAnswerList";
import {requestMemberComponentsSync} from "../../usefuls/helpers";

const CustomAnnexure = ({ annexure, componentIndex, answerId = null, answerList, going_next, staticSection, ...customProps }) => {
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })

    useEffect(() => {
        setValue("annexure", annexure)
    }, [annexure, setValue]);

    const debouncedOnChange = debounce(({ target: { name: annexureName, value: annexureVal } }) => {
        const updatedList = {...annexure};
        updatedList.value[annexureName] = annexureVal;
        let updatedData = [...answerList];
        updatedData[componentIndex] = { annexure: updatedList };
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);

    return (
        <Stack gap={2} mt="20px">
            <Typography>Annexure</Typography>
            <Box display="flex" alignItems="center">
                <Controller
                    control={control}
                    name={`annexure.value.lettersInpVal`}
                    render={({field: { onChange, value, ref, onBlur}}) => (
                        <TextField
                            name="lettersInpVal"
                            size="small"
                            placeholder="A,B...Z"
                            sx={{ width: "160px" }}
                            onChange={e => {
                                onChange(e);
                                debouncedOnChange(e)
                            }}
                            value={value}
                            inputRef={ref}
                            onBlur={onBlur}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name={`annexure.value.titleVal`}
                    render={({field: { onChange, value, ref, onBlur}}) => (
                        <TextField
                            name="titleVal"
                            size="small"
                            sx={{ ml: "10px" }}
                            placeholder="Title"
                            onChange={e => {
                                onChange(e);
                                debouncedOnChange(e)
                            }}
                            value={value}
                            inputRef={ref}
                            onBlur={onBlur}
                        />
                    )}
                />
            </Box>
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                {...customProps}
            />
        </Stack>
    )
}

export default CustomAnnexure;