import React, {useCallback, useEffect} from "react"
import {debounce, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import RemoveIcon from '@mui/icons-material/Remove';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import {_commentListAlphabet} from "../../_mock";
import CustomAnswerList from "./CustomAnswerList";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {requestMemberComponentsSync} from "../../usefuls/helpers";


const CustomCommentList = ({ componentCommentList, componentIndex, answerId = null, answerList, going_next, staticSection, ...customProps }) => {
    const dispatch = useDispatch();
    const {
        control,
        setValue,
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur"
    })
    const { matterId } = useParams();
    const addListItem = useCallback(() => {
        const updatedData = [...answerList];
        updatedData[componentIndex].commentList.push({ id: Math.random(), value: "" })
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [answerId, answerList, componentIndex, customProps, dispatch, going_next, matterId, staticSection])
    const removeListItem = useCallback((index) => {
        const updatedData = [...answerList];
        updatedData[componentIndex].commentList =
            updatedData[componentIndex].commentList.filter((_, uuuu) => index !== uuuu)
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId,
                dispatch,
                listItemHandle: true,
                going_next,
                staticSection
            }
        )
    }, [answerId, answerList, componentIndex, customProps, dispatch, going_next, matterId, staticSection])

    useEffect(() => {
        setValue("commentList", componentCommentList)
    }, [componentCommentList, setValue]);

    const debouncedOnChange = debounce(({ target: { id: comListId, value: comListVal } }) => {
        const updatedList = [...componentCommentList].map(cListItem => {
            if (String(cListItem.id) === String(comListId)) {
                return {
                    id: cListItem.id,
                    value: comListVal
                }
            }
            return cListItem
        })
        let updatedData = [...answerList];
        updatedData[componentIndex] = { commentList: updatedList };
        requestMemberComponentsSync(
            {
                customProps,
                matterId,
                updatedData,
                answerId,
                dispatch,
                going_next,
                staticSection
            }
        )
    }, 500);

    return (
        <Stack gap={2} mt="20px">
            <Typography>Comment List</Typography>
            {componentCommentList.map(({ id: elemId }, listItemIndex) => {
                return <Box display="flex" alignItems="center" height="50px">
                    <Typography>{_commentListAlphabet[listItemIndex]}</Typography>
                    <Controller
                        control={control}
                        name={`commentList.${listItemIndex}.value`}
                        render={({field: {onChange, value, ref, onBlur}}) => (
                            <TextField
                                name="commentList"
                                size="small"
                                id={elemId}
                                sx={{ ml: "10px" }}
                                placeholder="Text"
                                onChange={(e) => {
                                    onChange(e);
                                    debouncedOnChange(e)
                                }}
                                value={value}
                                inputRef={ref}
                                onBlur={onBlur}
                                InputProps={{
                                    disableUnderline: true,
                                    style: { fontStyle: 'italic' },
                                }}
                                InputLabelProps={{ shrink: true }}
                            />
                    )}/>
                    <Button onClick={() => {
                        removeListItem(listItemIndex)
                    }} sx={{ backgroundColor: "grey.300", color: "black.200", ml: "10px" }}>
                        <RemoveIcon />
                    </Button>
                    {listItemIndex === componentCommentList.length - 1 && (
                        <Button onClick={addListItem} sx={{ backgroundColor: "info.dark", color: "common.white", ml: "10px" }}>
                            +Add
                        </Button>
                    )}
                </Box>
            })}
            <CustomAnswerList
                componentIndex={componentIndex}
                answerList={answerList}
                answerId={answerId}
                going_next={going_next}
                staticSection={staticSection}
                {...customProps}
            />
        </Stack>
    )
}

export default CustomCommentList;