import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {requestMemberComponentsSync} from "../../usefuls/helpers";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

const CheckboxLabels = ({ required, title, id, onClick, checked, answerMemberId, answerId,updatedData, staticSection, ...customProps }) => {
    const checkedProp = checked !== undefined ?
        customProps.memberId === answerMemberId
            ? { checked: !!checked }
            : { checked: true }
        : {}
    const dispatch = useDispatch();
    const { matterId } = useParams();
    const handleClick = ({e, id}) => {
        if (!onClick) {
            requestMemberComponentsSync(
                {
                    customProps,
                    matterId,
                    answerId,
                    dispatch,
                    going_next: e.target.checked,
                    updatedData,
                    staticSection,
                }
            )
        } else {
            onClick(e.target.checked, id)
        }
    }
    return (
        <FormGroup>
            <FormControlLabel
                required={!!required}
                control={<Checkbox />}
                label={title}
                {...checkedProp}
                onClick={(e) => handleClick({e, id})}
            />
        </FormGroup>
    );
}
export default CheckboxLabels;