import Stack from "@mui/material/Stack";
import TextEditor from "../GlobalComponents/TextEditor";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import React from "react";
import { useSelector } from "react-redux";
import {stepButtonSXHandler} from "../../usefuls/helpers";
import {useRouter} from "../../usefuls/hooks";

const SecondStep = () => {
    const router = useRouter();
    const editorResults = useSelector(state => state.matters.editorResults)
    const currentStep = +window.location.search.split("?step=")[1];
    const handleChangeStep = (step) => {
        router.queryPush({ step })
    }
    return (
        <Container fixed sx={{padding: "40px 0"}}>
            <Stack gap={5}>
                {editorResults?.map(({ id, content }) => (
                    <TextEditor key={id} id={id} content={content}/>
                ))}
            </Stack>
            <Button
                sx={stepButtonSXHandler({float: { float: "left" } })}
                onClick={() => handleChangeStep(currentStep - 1)}
            >
                Previous Step
            </Button>
            <Button
                sx={stepButtonSXHandler({float: { float: "right" } })}
                onClick={() => handleChangeStep(3)}
            >
                Next Step
            </Button>
        </Container>
    )
}

export default SecondStep;