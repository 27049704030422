import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'white',
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    maxHeight: "700px",
    overflowY: "auto"
};

const TermsModal = (
    {
        termsOpened,
        setTermsOpened
    }
) => {
    return (
        <Modal
            open={termsOpened}
            onClose={() => setTermsOpened(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.3)' } }}
        >
            <Box sx={style}>
                <Stack gap={5} >
                    <Typography variant="h4" margin="0 auto">Interview Guidelines</Typography>
                    <Typography sx={{ display: "flex", gap: "5px" }}>
                        Discrimination Interview Guidelines for the Interviewer

                        Dear interviewer, below you will find extensive questions regarding discrimination, which will help you conduct more in-depth interviews.

                        Direct Speech

                        What is crucial is you record and capture the direct speech, what direct speech means, is all the comments that our client experienced and heard by the individual or group of employees who made the discriminatory comments. The more in depth the direct speech is, the more we can help our client.

                        Dates and Times and Locations and the Context of the Incident

                        Please ask in depth information, as to the dates and times and location of the discriminatory comments, and the regularity, and the context. If the client cannot give you specific dates and times, not an issue, ask for an approximate time.

                        Please ensure you ask who was present when the comments were made

                        Diary Notes and Record

                        Ask the client if they kept any diary notes and records, or any other documents to prove the discrimination.



                        Discrimination Interview Guidelines for the Client



                        We are now going to be asking you some very specific questions regarding the discrimination you experienced at work.

                        We are going to cover all aspects of your discrimination within a course of 2-4 interviews, hence please try to stick to the question being asked, so as we would keep better track of your experiences and would not miss or lose any information.

                        We understand that recalling experiences of discrimination and harassment can be overwhelming and upsetting, hence if at any point you need a break, just let me know.
                        Keep in mind that I will am typing all information, so please bear with me if at times I ask to repeat the answer or talk slower.



                        THE IMPORTANCE OF FACTUAL CHRONOLOGY

                        The details are very important for your statement, hence please bear with me as I will be asking specific details.

                        It is very important that you provide us detailed information, such as;

                        Name and surname and position of any individual), who made racially hurtful and offensive comments towards you;

                        The time and the location of the discriminatory comments and incidents;

                        The body language and tone of the individual who made the discriminatory comment or conduct;

                        The direct speech and conversations;

                        Details of the witnesses of the discriminatory comments and conduct;

                        What was your reaction (e.g. did you tell the person to back off) and what was the conversation following the incident?

                        What was the reaction of your fellow-employees?


                        The more detailed information you provide, the better it will assist us to structure and write a strong statement regarding the discrimination and harassment that you suffered, but do not worry, we are going to guide and lead you throughout the interview and we will be asking all the necessary questions.


                        .
                    </Typography>
                    <LoadingButton
                        sx={{
                            backgroundColor: "black.100",
                            color: "common.white",
                            p: "10px 50px",
                            borderRadius: "4px",
                            width: "max-content",
                            margin: "0 auto"
                        }}
                        onClick={() => setTermsOpened(false)}
                    >
                        Accept
                    </LoadingButton>
                </Stack>
            </Box>
        </Modal>
    )
}

export default TermsModal;
